
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrimaryButton",
  props: {
    text: { type: String, default: "" },
    isShowText: { type: Boolean, default: true },
    icon: { type: String, default: "" },
    iconClass: { type: String, default: "" },
  },
  emits: ["onClick"],
});
