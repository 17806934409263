export enum SortOrderType {
  ASC = 0,
  DESC = 1,
}

export enum SortOrderOfCompany {
  CREATED_AT = 0,
  COMPANY_NAME = 1,
  PARTNER = 2,
  COMPLETED_CONTRACT_NUMBER_THIS_MONTH = 3,
  COMPLETED_CONTRACT_NUMBER = 4,
  COUNT_OF_MEMBERS = 5,
  PLAN = 6,
  REPRESENTATIVE_NAME = 7,
  LATEST_PAYMENT_STATUS = 8,
}

/** 
 * @param headerColumn Header column number
 * @param sortColumn Number currently set to sort
 * @param sortOrderType ASC or DESC for SortOrderType
 * @returns string: icon path
*/
export const sortOrderIcon = (headerColumn: number, sortColumn: number, sortOrderType: SortOrderType): string => {
  if (headerColumn === sortColumn) {
    if (sortOrderType === SortOrderType.ASC) {
      return require('icons/sort-order-asc.svg')
    }
    return require('icons/sort-order-desc.svg')
  }
  return ''
}
