
import { defineComponent, reactive, toRefs, inject, ref, watch } from "vue";
import { formatDate } from "@/lib/utility/dateTime";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";
import ActionDropdown from "@/components/atomics/dropdown/ActionDropdown.vue";
import ItemInList from "@/components/atomics/list-item/ItemInList.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import PlanInfo from "@/models/PlanInfo";

interface ActionItem {
  action: () => void;
  icon: string;
  text: string;
}

const __default__ = defineComponent({
  name: "CompanyTitle",
  components: {
    Vue3Popper,
    ItemInList,
    ActionDropdown,
    TextButton,
  },
  emits: ["onChangePlan", "onDeleteCompany", "onSelect" ],
  props: {
    index: { type: Number, default: 0 },
    company: {},
    id: { type: String, default: "" },
    name: { type: String, default: "" },
    logo: { type: String, default: "" },
    representativeLastName: { type: String, default: "" },
    representativeFirstName: { type: String, default: "" },
    createdAt: { type: String, default: "" },
    planType: { type: Number, default: 0 },
    primaryPaymentMethodInfo: { type: String, default: "" },
    paidAt: { type: String, default: "" },
    referralCompanyName: { type: String, default: "" },
    completedContractNumberThisMonth: { type: Number, default: 0 },
    completedContractNumber: { type: Number, default: 0 },
    memberNumber: { type: Number, default: 0 },
    canDelete: { type: Boolean },
    canUpdate: { type: Boolean },
    selectedCompanyIds: { type: Array },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();

    const planTypeList = inject("planTypeList");
    const actionDropdown = ref<InstanceType<typeof ActionDropdown>>();
    const openDropdownBtn = ref<InstanceType<typeof TextButton>>();
    const isShowActionDropdown = ref(false);
    const trialMethod = PlanInfo.PaymentMethod.NO_PAYMENT_METHOD

    const state = reactive({
      baseAvatarUrl: process.env.VUE_APP_API_CLOUD_URL,
      planTypeState: props.planType,
      planTypeList,
      isHover: false,
      isSelected: false,
      selectedList: props.selectedCompanyIds,
    });

    const actionDropdownList = (id: string, name: string, planType: number, primaryPaymentMethodInfo: string) => [
      {
        action: (value: unknown) => {
          emit("onChangePlan", value, props.id, props.name);
        },
        type: "toggle",
        isActBeforeToggle: true,
        text: t("company.textButtonToggleChangePlan"),
        disabled: !props.canUpdate,
        toggleValue: planType && (primaryPaymentMethodInfo == trialMethod),
      },
      {
        action: () => {
          router.push({ path: `/company/` + id });
        },
        icon: "icons/details.svg",
        text: t("company.textButtonShowDetail"),
      },
      {
        action: () => {
          emit("onDeleteCompany", { id, name, planType, primaryPaymentMethodInfo });
        },
        icon: "icons/delete.svg",
        text: t("company.textButtonDelete"),
        disabled: !props.canDelete,
        isDelete: true,
      },
    ] as Array<ActionItem>;

    const clickTitle = (id: string) => {
      router.push( {path: `/company/` + id} );
    };

    const focusout = (e: FocusEvent) => {
      if (
        (actionDropdown.value?.$el as HTMLElement)?.contains(
          e.relatedTarget as HTMLElement
        )
      )
        isShowActionDropdown.value = true;
      else if (
        (actionDropdown.value?.$el as HTMLElement)?.contains(
          e.target as HTMLElement
        ) &&
        e.relatedTarget == openDropdownBtn.value?.$el
      )
      isShowActionDropdown.value = true;
      else isShowActionDropdown.value = false;
    };

    const focus = () => {
      isShowActionDropdown.value = !isShowActionDropdown.value;
    };

    const onSelect = (index: number, id: string) => {
      state.isHover = false;
      emit("onSelect", index, id, state.isSelected);
    };

    const handleClickIcon = (index: number, id: string) => {
      state.isSelected = true;
      onSelect(index, id);
    };

    watch(
      () => props.selectedCompanyIds,
      (selectedCompanyIds) => {
        state.isSelected =
          selectedCompanyIds?.some(
            (item: any) => item === props.id
          ) ?? false;
      }
    );

    return {
      ...toRefs(state),
      formatDate,
      actionDropdownList,
      clickTitle,
      isShowActionDropdown,
      trialMethod,
      focus,
      focusout,
      actionDropdown,
      onSelect,
      handleClickIcon,
      PlanInfo,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0e73d9d4": (_ctx.isShowActionDropdown ? 1: 0)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__