
import { defineComponent, computed, ref, onMounted } from "vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import SortButton from "@/components/atomics/button/SortButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import CompanyTitle from "./CompanyTitle.vue";
import {
  RepositoryFactory,
  CompanyRepository,
  OnboardingRepository,
  CampaignDetailRepository,
} from "@/lib/https";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import Tabs from "@/components/atomics/Tabs.vue";
import { useI18n } from "vue-i18n";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import ChangePlanPopup from "@/components/popups/company/ChangePlanPopup.vue";
import ChangeCustomerContactPopup from "@/components/popups/company/ChangeCustomerContactPopup.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import useList from "@/lib/compositional-logic/useList";
import ConfirmDeleteCompanyPopup from "@/components/popups/ConfirmPopup.vue";
import { AnonymousFunction } from "@/models/Function";
import { downloadCSV } from "@/lib/utility/downloadFile";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import BorderButton from "@/components/atomics/button/BorderButton.vue";
import useDeleteCompany, { companyToDelete } from "@/lib/compositional-logic/useDeleteCompany";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import PlanInfo from "@/models/PlanInfo";
import { SortOrderType, SortOrderOfCompany, sortOrderIcon } from "@/lib/utility/sortOrder";
import { ContactType } from "@/locales/ja/company";

export default defineComponent({
  name: "Company",
  components: {
    AfterLoginPage,
    TextButton,
    ValidationTextField,
    CompanyTitle,
    Tabs,
    ChangePlanPopup,
    ChangeCustomerContactPopup,
    ConfirmDeleteCompanyPopup,
    Dropdown,
    BorderButton,
    ToggleCheckbox,
    SortButton,
  },
  setup() {
    const { t } = useI18n();
    const { openNotification } = useNotificationControl();
    const { handleDeleteCompany } = useDeleteCompany();
    const { popupState, openPopup } = usePopupControl();

    const isShiftPress = ref(false)
    const selectedCompanyIds = ref(new Set<string>())
    const startIndex = ref(0)

    const companyState = ref({
      type: 0,
      name: "",
      action: AnonymousFunction,
    });

    const { getPreferences } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );

    const searchKeyword = ref("");
    const isShowFilter = ref(false);
    const partnerCompanyOptions = ref<Record<string, string>[]>([
      { id: "all", name: t("common.all") },
    ]);
    const planOptions = [
      { id: "all", name: t("common.all") },
      { id: "free", name: t("company.planFree") },
      { id: "lite", name: t("company.planLite") },
      { id: "standard", name: t("company.planStandard") },
      { id: "oemTermination", name: t("company.planOemFree") },
      { id: "oem1", name: t("company.planOem1") },
      { id: "oem2", name: t("company.planOem2") },
      { id: "oemLite", name: t("company.planOemLite") },
      { id: "oemStandard", name: t("company.planOemStandard") },
      { id: "oemFlatFree", name: t("company.planOemFlatFree") },
      { id: "oemFlatLite", name: t("company.planOemFlatLite") },
      { id: "oemFlatStandard", name: t("company.planOemFlatStandard") },
    ];
    const paymentStatusOptions = [
      { id: "all", name: t("common.all") },
      { id: "success", name: t("company.success") },
      { id: "failed", name: t("company.failed") },
    ];
    const partnerFilter = ref(partnerCompanyOptions.value[0].id);
    const planFilter = ref(planOptions[0].id);
    const isTrialFilter = ref(false);
    const paymentStatusFilter = ref(paymentStatusOptions[0].id);
    const sortColumn = ref(SortOrderOfCompany.CREATED_AT);
    const sortOrder = ref(SortOrderType.DESC);
    const clearFilter = () => {
      partnerFilter.value = partnerCompanyOptions.value[0].id;
      planFilter.value = planOptions[0].id;
      isTrialFilter.value = false;
      paymentStatusFilter.value = paymentStatusOptions[0].id;
      debouncedFilterList();
    };

    const {
      getListCompanies,
      switchToTrialOn,
      switchToTrialOff,
      downloadCompanyCSV,
      updateCustomerContacts,
      deleteCustomerContacts,
    } = RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const {
      list: companyListData,
      totalLength,
      loadMore,
      reloadList,
      setFilterKeyword,
      keyword,
      debouncedFilterList,
    } = useList({
      initialLoadList: async ({ pageSize, keyword }) =>
        await getListCompanies({
          pageSize,
          keyword,
          partnerCompany: partnerFilter.value,
          plan: planFilter.value,
          primaryPaymentMethodInfo: isTrialFilter.value
            ? PlanInfo.PaymentMethod.NO_PAYMENT_METHOD
            : "",
          paymentStatus: paymentStatusFilter.value,
          orderBy: sortColumn.value,
          sortOrder: sortOrder.value,
        }),
      loadMoreList: async ({ page, pageSize, keyword }) =>
        await getListCompanies({
          page,
          pageSize,
          keyword,
          partnerCompany: partnerFilter.value,
          plan: planFilter.value,
          primaryPaymentMethodInfo: isTrialFilter.value
            ? PlanInfo.PaymentMethod.NO_PAYMENT_METHOD
            : "",
          paymentStatus: paymentStatusFilter.value,
          orderBy: sortColumn.value,
          sortOrder: sortOrder.value,
        }),
      filterList: async ({ keyword, pageSize }) =>
        await getListCompanies({
          keyword,
          pageSize,
          partnerCompany: partnerFilter.value,
          plan: planFilter.value,
          primaryPaymentMethodInfo: isTrialFilter.value
            ? PlanInfo.PaymentMethod.NO_PAYMENT_METHOD
            : "",
          paymentStatus: paymentStatusFilter.value,
          orderBy: sortColumn.value,
          sortOrder: sortOrder.value,
        }),
    });

    const tabTags = computed(() => [
      {
        name: t("company.titleListCompanies"),
        total: totalLength.value,
      },
    ]);

    const onDeleteCompany = async ({ id = "", name = "", planType = 0, primaryPaymentMethodInfo = "" }) => {
      handleDeleteCompany({ id, name, planType, primaryPaymentMethodInfo })
    };

    const onDeleteDone = (message: string) => {
      reloadList();
      message 
        ? openNotification({ type: "error", text: message }) : openNotification();
    };

    const onDownloadCSV = async () => {
      const response = await downloadCompanyCSV({ keyword: keyword.value });

      downloadCSV(response, "company");
    };

    const handleChangePlan = (value: boolean, companyId: string, name: string) => {
      openPopup("changePlanCompany");

      companyState.value.name = name;

      if (value) {
        companyState.value.type = 0;
        companyState.value.action = () => switchToTrialOn(companyId);
      } else {
        companyState.value.type = 1;
        companyState.value.action = () => switchToTrialOff(companyId);
      }
    };

    const openCustomerContactPopup = () => {
      openPopup("changeCustomerContact")
    }

    const {
      createCampaignDetail,
      deleteCampaignDetail,
    } = RepositoryFactory.getRepository<CampaignDetailRepository>(CampaignDetailRepository);

    const handleChangeCustomerContact = async (contactType: number, sendEmail: boolean) => {
      try {
        if (contactType == ContactType.CustomerSurvey) {
          var companyIds = Array.from(selectedCompanyIds.value)
          if (sendEmail) {
            return updateCustomerContacts(companyIds, contactType)
          } else {
            return deleteCustomerContacts(companyIds, contactType)
          }
        } else if (contactType == ContactType.ReleaseMail) {
          if (sendEmail) {
            return createCampaignDetail(contactType)
          } else {
            return deleteCampaignDetail(contactType)
          }
        }
      } catch (error) {
        return false;
      }
      selectedCompanyIds.value.clear()
    }

    const changeSuccess = () => {
      openNotification();
      reloadList();
    };

    const changeSortOrder = (column: SortOrderOfCompany) => {
      if (sortColumn.value != column) {
        sortColumn.value = column;
        sortOrder.value = SortOrderType.DESC;
      } else {
        if (sortOrder.value == SortOrderType.DESC) {
          sortOrder.value = SortOrderType.ASC;
        } else {
          sortOrder.value = SortOrderType.DESC;
        }
      }
      debouncedFilterList();
    }

    onMounted(async () => {
      const response = await getPreferences();
      if (response) {
        partnerCompanyOptions.value.push(...response);
      };

      window.addEventListener("keydown", (e) => {
        if (e.key === "Shift" && !isShiftPress.value) {
          isShiftPress.value = true;
        }
      });

      window.addEventListener("keyup", (e) => {
        if (e.key === "Shift" && isShiftPress.value) {
          isShiftPress.value = false;
        }
      });
    });

    return {
      tabTags,
      onDeleteCompany,
      onDownloadCSV,
      handleChangePlan,
      popupState,
      loadMore,
      companyListData,
      searchKeyword,
      setFilterKeyword,
      openNotification,
      handleDeleteCompany,
      onDeleteDone,
      companyToDelete,
      companyState,
      changeSuccess,
      partnerCompanyOptions,
      isShowFilter,
      planOptions,
      paymentStatusOptions,
      partnerFilter,
      planFilter,
      isTrialFilter,
      paymentStatusFilter,
      debouncedFilterList,
      sortColumn,
      sortOrder,
      SortOrderType,
      SortOrderOfCompany,
      changeSortOrder,
      clearFilter,
      openCustomerContactPopup,
      handleChangeCustomerContact,
      isShiftPress,
      selectedCompanyIds,
      startIndex,
      sortOrderIcon,
    };
  },
  methods: {
    onSelect(index: number, id: string, isSelected: boolean) {
      const totalSelect = this.selectedCompanyIds.size
      const hasCompanyId = this.selectedCompanyIds.has(id)
      if (this.isShiftPress && 0 < totalSelect) {
        this.handleShiftSelect(index,id, hasCompanyId, isSelected);
      } else {
        this.startIndex = index
        this.handleSelect(id, hasCompanyId, isSelected);
      }
    },
    
    /** Select a large number of companies in a range selection */
    handleShiftSelect(index: number, id: string, hasCompanyId: boolean, isSelected: boolean) {
      this.handleSelect(id, hasCompanyId, isSelected);
      var hasCompanyIdInDatas = false;
      var companyId = "";
      var [min, max]: any[] = [];
      if (index < this.startIndex) {
        [min, max] = [index, this.startIndex]
      } else {
        [min, max] = [this.startIndex, index]
      }

      for (let i = min; i <= max; i++) {
        companyId = this.companyListData[i].id;
        hasCompanyIdInDatas = this.selectedCompanyIds.has(companyId);
        if (i < this.companyListData.length) {
          (this.$refs.checkbox as any)[i].isSelected = isSelected
          this.handleSelect(companyId, hasCompanyIdInDatas, isSelected);
        }
      }
    },

    handleSelect(id: string, hasCompanyId: boolean, isSelected: boolean) {
      if (isSelected && !hasCompanyId) {
        this.selectedCompanyIds.add(id);
      }
      if (!isSelected && hasCompanyId) {
        this.selectedCompanyIds.delete(id);
      }
    },
  },
});
