import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c063ecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-icon" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "item-title" }
const _hoisted_4 = { class: "title-primary" }
const _hoisted_5 = { class: "title-secondary" }
const _hoisted_6 = { class: "partner-name" }
const _hoisted_7 = { class: "completed-contract-number" }
const _hoisted_8 = { class: "completed-contract-number" }
const _hoisted_9 = { class: "completed-contract-number" }
const _hoisted_10 = { class: "plan-type" }
const _hoisted_11 = { class: "representative-name" }
const _hoisted_12 = { class: "info-pay" }
const _hoisted_13 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ActionDropdown = _resolveComponent("ActionDropdown")!
  const _component_ItemInList = _resolveComponent("ItemInList")!

  return (_openBlock(), _createBlock(_component_ItemInList, {
    class: _normalizeClass(["company-tile", {
      'bg-grey': _ctx.isHover && !_ctx.isSelected,
      'bg-checked': _ctx.isSelected,
    }]),
    onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isHover = false)),
    onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isHover = true))
  }, {
    head: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isHover || _ctx.isSelected)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "checkbox",
              class: "mr-1 input-checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSelected) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSelect(_ctx.index, _ctx.id)))
            }, null, 544)), [
              [_vModelCheckbox, _ctx.isSelected]
            ])
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.logo ? _ctx.baseAvatarUrl + _ctx.logo : require('icons/default-avatar.svg'),
              alt: "avatar",
              class: "mr-1 avatar bordered-avatar",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClickIcon(_ctx.index, _ctx.id)))
            }, null, 8, _hoisted_2))
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          _createVNode(_component_Vue3Popper, { content: _ctx.name }, {
            default: _withCtx(() => [
              _createElementVNode("p", {
                class: "title-name",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickTitle(_ctx.id)))
              }, _toDisplayString(_ctx.name), 1)
            ]),
            _: 1
          }, 8, ["content"])
        ]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.createdAt ? _ctx.$t("common.createdDate") + " " + _ctx.formatDate(_ctx.createdAt, "YYYY/MM/DD"): ""), 1)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.referralCompanyName), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.completedContractNumberThisMonth), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.completedContractNumber), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.memberNumber), 1),
      _createElementVNode("div", _hoisted_10, [
        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.PlanInfo.getPlanName(_ctx.planType))) + " ", 1),
        (_ctx.primaryPaymentMethodInfo == _ctx.trialMethod)
          ? (_openBlock(), _createBlock(_component_TextButton, {
              key: 0,
              class: "trial-btn",
              icon: require('icons/flag.svg'),
              "is-show-text": false
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_Vue3Popper, {
          content: _ctx.representativeLastName + _ctx.representativeFirstName
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.representativeLastName + _ctx.representativeFirstName), 1)
          ]),
          _: 1
        }, 8, ["content"])
      ]),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.paidAt ? _ctx.formatDate(_ctx.paidAt, "YYYY/MM/DD") : "-"), 1)
    ]),
    tail: _withCtx(() => [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_TextButton, {
          class: "option-btn",
          ref: "openDropdownBtn",
          icon: require('icons/more.svg'),
          "is-show-text": false,
          onClick: _ctx.focus,
          onBlur: _ctx.focusout
        }, null, 8, ["icon", "onClick", "onBlur"]),
        (_ctx.isShowActionDropdown)
          ? (_openBlock(), _createBlock(_component_ActionDropdown, {
              key: 0,
              ref: "actionDropdown",
              tabindex: "0",
              items: _ctx.actionDropdownList(_ctx.id, _ctx.name, _ctx.planType, _ctx.primaryPaymentMethodInfo),
              onActionDone: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isShowActionDropdown = false)),
              onBlur: _ctx.focusout,
              "indicator-position": "10px"
            }, null, 8, ["items", "onBlur"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}