import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f692fd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.iconClass)
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(["button text-button primary_button", { 'icon-button': _ctx.icon }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick')))
    }, [
      (_ctx.isShowText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true),
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "icon",
            src: _ctx.icon
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}